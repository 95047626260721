<template>
  <main class="mb-16 px-4 max-w-sm md:max-w-3xl lg:max-w-6xl mx-auto">
    <div class="flex justify-between items-center my-10">
      <h2
        class="my-10 text-2xl font-semibold text-gray-700 dark:text-gray-200 flex uppercase"
      >
        <router-link class="w-10 block" tag="a" :to="{ name: 'categories' }">
          <svg
            class="h-8 w-8 fill-current text-gray-700 dark:text-gray-200"
            style="enable-background: new 0 0 512 512"
            version="1.1"
            viewBox="0 0 512 512"
            xml:space="preserve"
          >
            <polygon
              points="352,128.4 319.7,96 160,256 160,256 160,256 319.7,416 352,383.6 224.7,256 "
            />
          </svg>
        </router-link>
        Sub Categories
      </h2>

      <router-link
        :to="{
          name: 'sub-category-create',
          params: { category: this.$route.params.category },
        }"
        class="uppercase flex items-center justify-between px-4 py-2 text-xs md:text-sm font-medium leading-5 text-white transition-colors duration-300 bg-black border border-transparent rounded-lg active:bg-purple-600 hover:bg-gray-700 focus:outline-none focus:shadow-outline-purple"
      >
        Add Sub Category
        <span class="ml-2" aria-hidden="true">+</span>
      </router-link>
    </div>

    <div class="bg-white dark:bg-gray-800 mt-10">
      <filter-button id="filters">
        <template v-slot:search>
          <div class="w-56 flex md:ml-0 bg-white dark:bg-gray-800">
            <label for="search_field" class="sr-only">Search</label>
            <div
              class="relative w-full text-gray-400 focus-within:text-gray-600"
            >
              <div
                class="pointer-events-none absolute inset-y-0 left-0 flex items-center bg-white dark:bg-gray-800"
              >
                <svg
                  class="h-5 w-5 stroke-current"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M20.9999 21L16.6499 16.65"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>

              <div
                v-if="categoyName"
                class="absolute inset-y-0 right-0 focus:outline-none flex items-center"
              >
                <button type="button" @click="clearSearchInput">
                  <svg
                    class="h-5 w-5 stroke-current"
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.7612 6.7998L6.76123 18.7998"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.76123 6.7998L18.7612 18.7998"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <input
                autocomplete="off"
                @keyup="GetSubCategories()"
                v-model="categoyName"
                id="search_field"
                class="bg-white dark:bg-gray-800 block h-full w-full border-0 py-2 pl-8 pr-3 text-gray-900 dark:text-grey-H-400 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                placeholder="Search"
                type="text"
                name="search"
              />
            </div>
          </div>
        </template>
        <div
          id="filters"
          class="hidden mt-8 grid grid-cols-2 gap-8 max-w-lg mx-auto text-black dark:text-grey-H-400"
        ></div>
      </filter-button>
    </div>

    <div
      class="bg-gray-50 dark:text-gray-400 dark:bg-gray-800 rounded-lg shadow-md relative mt-6"
    >
      <div v-if="subCategroies.length != 0" class="w-full whitespace-no-wrap">
        <div
          class="flex justify-between items-center pt-4 pb-2 text-xs font-semibold tracking-wide text-left text-gray-500 border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800"
        >
          <span class="w-1/2 md:w-3/12 px-4 py-3">Name</span>
          <span class="w-1/2 md:w-3/12 px-4 py-3">product</span>
          <span class="w-1/2 md:w-3/12 px-4 py-3">Created At</span>
          <span class="w-2/12"></span>
          <span class="w-1/2 md:w-1/12 px-4 py-3"></span>
        </div>
        <div class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
          <div
            :key="id"
            v-for="(subcategory, id) in subCategroies"
            class="flex justify-between items-center text-gray-700 dark:text-gray-400"
          >
            <div
              class="w-1/2 md:w-3/12 px-4 py-3 text-sm flex items-center space-x-2"
            >
              <div
                class="relative hidden rounded-full md:block overflow-hidden"
              >
                <img :src="subcategory.image" class="w-8 h-8 object-cover" />
                <div
                  class="absolute inset-0 rounded-full shadow-inner"
                  aria-hidden="true"
                ></div>
              </div>
              <p class="font-semibold">{{ subcategory.categoryName }}</p>
            </div>
            <div class="w-1/2 md:w-3/12 px-4 py-3 text-sm">
              <p class="font-semibold">{{ subcategory.countProduct }}</p>
            </div>
            <div class="w-1/2 md:w-3/12 px-4 py-3 text-sm">
              {{ subcategory.createdOn }}
            </div>

            <div class="w-2/12"></div>

            <div
              class="w-1/2 md:w-1/12 px-4 py-3 text-sm relative flex justify-center"
            >
              <procedures-button :id="id">
                <div
                  :id="id"
                  class="hidden w-48 p-4 space-y-4 bg-gray-50 dark:bg-gray-700 absolute left-0 -ml-32 z-40 shadow"
                >
                  <router-link
                    class="space-x-4 flex items-center"
                    tag="a"
                    :to="{
                      name: 'sub-category-edit',
                      params: { subcategory: subcategory.categoryId },
                    }"
                  >
                    <svg
                      class="w-5 h-5 fill-current"
                      height="401pt"
                      viewBox="0 -1 401.52289 401"
                      width="401pt"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="m370.589844 250.972656c-5.523438 0-10 4.476563-10 10v88.789063c-.019532 16.5625-13.4375 29.984375-30 30h-280.589844c-16.5625-.015625-29.980469-13.4375-30-30v-260.589844c.019531-16.558594 13.4375-29.980469 30-30h88.789062c5.523438 0 10-4.476563 10-10 0-5.519531-4.476562-10-10-10h-88.789062c-27.601562.03125-49.96875 22.398437-50 50v260.59375c.03125 27.601563 22.398438 49.96875 50 50h280.589844c27.601562-.03125 49.96875-22.398437 50-50v-88.792969c0-5.523437-4.476563-10-10-10zm0 0"
                      />
                      <path
                        d="m376.628906 13.441406c-17.574218-17.574218-46.066406-17.574218-63.640625 0l-178.40625 178.40625c-1.222656 1.222656-2.105469 2.738282-2.566406 4.402344l-23.460937 84.699219c-.964844 3.472656.015624 7.191406 2.5625 9.742187 2.550781 2.546875 6.269531 3.527344 9.742187 2.566406l84.699219-23.464843c1.664062-.460938 3.179687-1.34375 4.402344-2.566407l178.402343-178.410156c17.546875-17.585937 17.546875-46.054687 0-63.640625zm-220.257812 184.90625 146.011718-146.015625 47.089844 47.089844-146.015625 146.015625zm-9.40625 18.875 37.621094 37.625-52.039063 14.417969zm227.257812-142.546875-10.605468 10.605469-47.09375-47.09375 10.609374-10.605469c9.761719-9.761719 25.589844-9.761719 35.351563 0l11.738281 11.734375c9.746094 9.773438 9.746094 25.589844 0 35.359375zm0 0"
                      />
                    </svg>
                    <span>Edit</span>
                  </router-link>
                  <button
                    class="space-x-4 flex items-center"
                    @click="prepareDelete(subcategory.categoryName, subcategory.categoryId, id)"
                  >
                    <svg
                      class="w-5 h-5 fill-current"
                      height="512pt"
                      viewBox="-57 0 512 512"
                      width="512pt"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="m156.371094 30.90625h85.570312v14.398438h30.902344v-16.414063c.003906-15.929687-12.949219-28.890625-28.871094-28.890625h-89.632812c-15.921875 0-28.875 12.960938-28.875 28.890625v16.414063h30.90625zm0 0"
                      />
                      <path
                        d="m344.210938 167.75h-290.109376c-7.949218 0-14.207031 6.78125-13.566406 14.707031l24.253906 299.90625c1.351563 16.742188 15.316407 29.636719 32.09375 29.636719h204.542969c16.777344 0 30.742188-12.894531 32.09375-29.640625l24.253907-299.902344c.644531-7.925781-5.613282-14.707031-13.5625-14.707031zm-219.863282 312.261719c-.324218.019531-.648437.03125-.96875.03125-8.101562 0-14.902344-6.308594-15.40625-14.503907l-15.199218-246.207031c-.523438-8.519531 5.957031-15.851562 14.472656-16.375 8.488281-.515625 15.851562 5.949219 16.375 14.472657l15.195312 246.207031c.527344 8.519531-5.953125 15.847656-14.46875 16.375zm90.433594-15.421875c0 8.53125-6.917969 15.449218-15.453125 15.449218s-15.453125-6.917968-15.453125-15.449218v-246.210938c0-8.535156 6.917969-15.453125 15.453125-15.453125 8.53125 0 15.453125 6.917969 15.453125 15.453125zm90.757812-245.300782-14.511718 246.207032c-.480469 8.210937-7.292969 14.542968-15.410156 14.542968-.304688 0-.613282-.007812-.921876-.023437-8.519531-.503906-15.019531-7.816406-14.515624-16.335937l14.507812-246.210938c.5-8.519531 7.789062-15.019531 16.332031-14.515625 8.519531.5 15.019531 7.816406 14.519531 16.335937zm0 0"
                      />
                      <path
                        d="m397.648438 120.0625-10.148438-30.421875c-2.675781-8.019531-10.183594-13.429687-18.640625-13.429687h-339.410156c-8.453125 0-15.964844 5.410156-18.636719 13.429687l-10.148438 30.421875c-1.957031 5.867188.589844 11.851562 5.34375 14.835938 1.9375 1.214843 4.230469 1.945312 6.75 1.945312h372.796876c2.519531 0 4.816406-.730469 6.75-1.949219 4.753906-2.984375 7.300781-8.96875 5.34375-14.832031zm0 0"
                      />
                    </svg>
                    <span>Delete</span>
                  </button>
                </div>
              </procedures-button>
            </div>
          </div>
        </div>
        <div
          class="flex justify-between items-center px-4 py-3 text-xs font-semibold tracking-wide text-gray-500 uppercase border-t dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800"
        >
          <el-pagination
            background
            :page-size="pageSize"
            layout="prev, pager, next"
            :total="total"
            :current-page.sync="pageNo"
            @current-change="PageChanged"
            class="pagination justify-content-center pagination-sm"
          >
          </el-pagination>
        </div>
      </div>

      <div
        v-else
        id="emptyState"
        class="text-gray_5 text-sm text-center h-96 flex items-center justify-center"
      >
        <div
          v-if="loading"
          id="testtest"
          class="h-64 flex justify-center items-center"
        >
          <svg
            class="w-10 h-w-10 rounded-full"
            viewBox="0 0 38 38"
            stroke="#fff"
          >
            <g fill="none">
              <g transform="translate(1 1)" stroke-width="2">
                <circle
                  stroke="#E1E7EC"
                  stroke-opacity=".5"
                  cx="18"
                  cy="18"
                  r="18"
                />
                <path stroke="black" d="M36 18c0-9.94-8.06-18-18-18">
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0 18 18"
                    to="360 18 18"
                    dur="1s"
                    repeatCount="indefinite"
                  />
                </path>
              </g>
            </g>
          </svg>
        </div>
        <div
          v-else
          class="h-64 flex justify-center items-center w-full bg-white dark:bg-gray-800"
        >
          No Sub Category Yet..
        </div>
      </div>
    </div>






    <!-- Start making sure delete it  -->
    <div v-show="makingSureDeleteIt">
      <button
        @click="makingSureDeleteIt = false"
        class="absolute bg-black bg-opacity-60 w-full h-full inset-0 top-0 z-40 flex justify-center items-center cursor-auto"
      >
        <div class="bg-white p-7 w-full max-w-lg rounded-xl cursor-auto z-50">
          <div class="flex justify-between items-start">
            <div class="text-left">
              <p class="text-2xl font-bold">
                Are you sure to delete {{ prepareDeleteCategoryName }} ?
              </p>
              <p class="text-sm mt-2">It will be permanently deleted.</p>
            </div>

            <button
              @click="makingSureDeleteIt = false"
              class="focus:outline-none"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.3941 19.5531L11.999 14.158L6.60398 19.5531C6.00806 20.149 5.04188 20.149 4.44596 19.5531C3.85004 18.9571 3.85004 17.991 4.44596 17.395L9.84101 12L4.44596 6.60496C3.85004 6.00904 3.85004 5.04286 4.44596 4.44694C5.04188 3.85102 6.00806 3.85102 6.60398 4.44694L11.999 9.84198L17.3941 4.44694C17.99 3.85102 18.9562 3.85102 19.5521 4.44694C20.148 5.04286 20.148 6.00904 19.5521 6.60496L14.157 12L19.5521 17.395C20.148 17.991 20.148 18.9571 19.5521 19.5531C18.9562 20.149 17.99 20.149 17.3941 19.5531Z"
                  fill="#676767"
                />
              </svg>
            </button>
          </div>

          <div class="mt-10 space-x-4 flex justify-start">
            <button
              @click="
                deleteButton(
                  prepareDeleteCategoryId,
                  prepareDeleteCategoryIndex
                )
              "
              class="uppercase bg-black text-xs md:text-sm font-medium tracking-widest w-32 py-3 text-surface-white rounded focus:outline-none hover:bg-white hover:text-black border hover:border-black transform duration-700"
            >
              Delete
            </button>
            <button
              @click="makingSureDeleteIt = false"
              class="uppercase bg-white text-xs md:text-sm font-medium tracking-widest w-32 py-3 text-black border border-black rounded focus:outline-none hover:shadow-xl transform duration-700"
            >
              Cancel
            </button>
          </div>
        </div>
      </button>
    </div>
    <!-- End making sure delete it  -->



    <div
      v-if="showAlert"
      class="absolute inset-0 h-full w-full bg-gray-700 bg-opacity-75 flex justify-center items-center"
    >
      <div
        v-if="showAlertLoading"
        class="h-64 flex justify-center items-center"
      >
        <svg class="w-10 h-w-10 rounded-full" viewBox="0 0 38 38" stroke="#fff">
          <g fill="none">
            <g transform="translate(1 1)" stroke-width="2">
              <circle
                stroke="#E1E7EC"
                stroke-opacity=".5"
                cx="18"
                cy="18"
                r="18"
              />
              <path stroke="black" d="M36 18c0-9.94-8.06-18-18-18">
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 18 18"
                  to="360 18 18"
                  dur="1s"
                  repeatCount="indefinite"
                />
              </path>
            </g>
          </g>
        </svg>
      </div>

      <div v-else class="w-1/3 bg-cool-gray-100 rounded">
        <button
          v-if="successed"
          @click="showAlert = false"
          class="absolute bg-black bg-opacity-60 w-full h-full inset-0 top-0 z-40 flex justify-center items-center cursor-auto"
        >
          <div class="bg-white p-7 w-full max-w-lg rounded-xl cursor-auto z-50">
            <div class="flex justify-end">
              <button @click="showAlert = false" class="focus:outline-none">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M17.3941 19.5531L11.999 14.158L6.60398 19.5531C6.00806 20.149 5.04188 20.149 4.44596 19.5531C3.85004 18.9571 3.85004 17.991 4.44596 17.395L9.84101 12L4.44596 6.60496C3.85004 6.00904 3.85004 5.04286 4.44596 4.44694C5.04188 3.85102 6.00806 3.85102 6.60398 4.44694L11.999 9.84198L17.3941 4.44694C17.99 3.85102 18.9562 3.85102 19.5521 4.44694C20.148 5.04286 20.148 6.00904 19.5521 6.60496L14.157 12L19.5521 17.395C20.148 17.991 20.148 18.9571 19.5521 19.5531C18.9562 20.149 17.99 20.149 17.3941 19.5531Z"
                    fill="#676767"
                  />
                </svg>
              </button>
            </div>

            <div class="flex justify-center mt-4">
              <svg
                width="60"
                height="60"
                viewBox="0 0 80 80"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="40"
                  cy="40"
                  r="38"
                  stroke="black"
                  stroke-width="4"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M56.3149 28.7293C57.2486 29.6808 57.225 31.2002 56.2621 32.123L36.2264 51.323C35.2843 52.2257 33.7871 52.2257 32.8451 51.323L23.7379 42.5957C22.775 41.673 22.7514 40.1535 23.6851 39.202C24.6188 38.2504 26.1563 38.227 27.1192 39.1498L34.5357 46.2569L52.8808 28.677C53.8437 27.7543 55.3812 27.7777 56.3149 28.7293Z"
                  fill="black"
                />
              </svg>
            </div>

            <div class="text-center mt-8">
              <p class="text-2xl font-bold">{{ successedMessage }}</p>
            </div>

            <div class="mt-10 flex justify-center">
              <button
                @click="showAlert = false"
                class="bg-black text-xs md:text-sm font-normal tracking-widest w-32 py-3 text-surface-white rounded focus:outline-none hover:bg-white hover:text-black border hover:border-black transform duration-700"
              >
                Close
              </button>
            </div>
          </div>
        </button>

        <button
          v-else
          @click="showAlert = false"
          class="absolute bg-black bg-opacity-60 w-full h-full inset-0 top-0 z-40 flex justify-center items-center cursor-auto"
        >
          <div class="bg-white p-7 w-full max-w-lg rounded-xl cursor-auto z-50">
            <div class="flex justify-end">
              <button @click="showAlert = false" class="focus:outline-none">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M17.3941 19.5531L11.999 14.158L6.60398 19.5531C6.00806 20.149 5.04188 20.149 4.44596 19.5531C3.85004 18.9571 3.85004 17.991 4.44596 17.395L9.84101 12L4.44596 6.60496C3.85004 6.00904 3.85004 5.04286 4.44596 4.44694C5.04188 3.85102 6.00806 3.85102 6.60398 4.44694L11.999 9.84198L17.3941 4.44694C17.99 3.85102 18.9562 3.85102 19.5521 4.44694C20.148 5.04286 20.148 6.00904 19.5521 6.60496L14.157 12L19.5521 17.395C20.148 17.991 20.148 18.9571 19.5521 19.5531C18.9562 20.149 17.99 20.149 17.3941 19.5531Z"
                    fill="#676767"
                  />
                </svg>
              </button>
            </div>

            <div class="flex justify-center mt-4">
              <svg
                width="80"
                height="80"
                viewBox="0 0 80 80"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="40"
                  cy="40"
                  r="38"
                  stroke="black"
                  stroke-width="4"
                />
                <path
                  d="M52 28L29 51"
                  stroke="black"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M29 28L52 51"
                  stroke="black"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>

            <div class="text-center mt-8">
              <p class="text-2xl font-bold">Delete Failed</p>
              <p class="text-sm mt-2">{{ erorrMessage }}</p>
            </div>

            <div class="mt-10 flex justify-center">
              <button
                @click="showAlert = false"
                class="bg-black text-xs md:text-sm font-normal tracking-widest w-32 py-3 text-surface-white rounded focus:outline-none hover:bg-white hover:text-black border hover:border-black transform duration-700"
              >
                Try Again
              </button>
            </div>
          </div>
        </button>
      </div>
    </div>


    
  </main>
</template>

<script>
export default {
  created() {
    this.categoryId = this.$route.params.category;
    this.GetSubCategories();
  },
  mounted() {
    // this.GetSubCategories();
  },
  data() {
    return {
      pageNo: 1,
      pageSize: 10,
      data: "",
      showRulers: false,
      loading: true,
      showFilter: true,
      showPagination: false,
      toWatchRulersRulersLength: "",
      toWatchPaginationLast_page: "",
      pagination: {},

      total: 0,
      totalRecords: 0,

      CoachesQuery: "",
      displayClearButton: false,

      Coaches: {},

      specializationFilter: [],
      specializationFilterCount: 0,
      specializationName: "التخصص",

      genderFilter: [],
      genderFilterCount: 0,
      genderName: "الجنس",

      bloodTypeFilter: [],
      bloodTypeFilterCount: 0,
      bloodTypeName: "فصيلة الدم",

      nationalityFilter: [],
      nationalityFilterCount: 0,
      nationalityName: "الجنسية",

      subCategroies: [],
      categoryId: null,
      categoyName: "",





      makingSureDeleteIt: false,
      deleteStatus: false,
      erorrMessage:'',

      errorStatus: false,
      prepareDeleteCategoryName: "",
      prepareDeleteCategoryId: "",
      prepareDeleteCategoryIndex: "",
      successedMessage:'',



      showAlert: false,
      showAlertLoading: false,
      successed: '',

    };
  },

  methods: {
    PageChanged(pageNo) {
      this.pageNo = pageNo;
      this.GetSubCategories();
    },
    clearSearchInput() {
      this.categoyName = "";
      this.GetSubCategories();
    },

    filterspecialization() {
      this.getRulers(1);
      document.getElementById("specializationFilter").style.display = "none";

      document.getElementById(
        "specializationFilterButton"
      ).style.backgroundColor =
        this.specializationFilterCount > 0 ? "#F8F9FA" : "";
    },
    clearspecializationFilter() {
      this.specializationFilter = [];
      this.specializationFilterCount = 0;
      this.getRulers(1);
      document.getElementById("specializationFilter").style.display = "none";

      document.getElementById(
        "specializationFilterButton"
      ).style.backgroundColor =
        this.specializationFilterCount > 0 ? "#F8F9FA" : "";
    },

    filtergender() {
      this.getRulers(1);
      document.getElementById("genderFilter").style.display = "none";

      document.getElementById("genderFilterButton").style.backgroundColor =
        this.genderFilterCount > 0 ? "#F8F9FA" : "";
    },
    cleargenderFilter() {
      this.genderFilter = [];
      this.genderFilterCount = 0;
      this.getRulers(1);
      document.getElementById("genderFilter").style.display = "none";

      document.getElementById("genderFilterButton").style.backgroundColor =
        this.genderFilterCount > 0 ? "#F8F9FA" : "";
    },

    filterbloodType() {
      this.getRulers(1);
      document.getElementById("bloodTypeFilter").style.display = "none";

      document.getElementById("bloodTypeFilterButton").style.backgroundColor =
        this.bloodTypeFilterCount > 0 ? "#F8F9FA" : "";
    },
    clearbloodTypeFilter() {
      this.bloodTypeFilter = [];
      this.bloodTypeFilterCount = 0;
      this.getRulers(1);
      document.getElementById("bloodTypeFilter").style.display = "none";

      document.getElementById("bloodTypeFilterButton").style.backgroundColor =
        this.bloodTypeFilterCount > 0 ? "#F8F9FA" : "";
    },

    filternationality() {
      this.getRulers(1);
      document.getElementById("nationalityFilter").style.display = "none";

      document.getElementById("nationalityFilterButton").style.backgroundColor =
        this.nationalityFilterCount > 0 ? "#F8F9FA" : "";
    },
    clearnationalityFilter() {
      this.nationalityFilter = [];
      this.nationalityFilterCount = 0;
      this.getRulers(1);
      document.getElementById("nationalityFilter").style.display = "none";

      document.getElementById("nationalityFilterButton").style.backgroundColor =
        this.nationalityFilterCount > 0 ? "#F8F9FA" : "";
    },
    GetSubCategories() {
      this.loading = true;
      this.displayClearButton = !!this.CoachesQuery;
      this.displayResultsCount =
        this.specializationFilterCount > 0 ||
        this.genderFilterCount > 0 ||
        this.bloodTypeFilterCount > 0 ||
        this.nationalityFilterCount > 0 ||
        this.CoachesQuery.length > 0;
      this.$http.categoriesService
        .GetSubCategories(
          this.pageNo,
          this.pageSize,
          this.categoryId,
          this.categoyName
        )
        .then((res) => {
          this.data = res.data;
          this.subCategroies = res.data.result.subCategoriesInfo;
          this.total = res.data.result.total;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },



    prepareDelete(categoryName, categoryId, index) {
      this.prepareDeleteCategoryName = categoryName;
      this.prepareDeleteCategoryId = categoryId;
      this.prepareDeleteCategoryIndex = index;
      this.makingSureDeleteIt = true;
    },

    deleteButton(subcategoryId, index) {
      this.showAlert = true;
      this.showAlertLoading = true;

      this.$http.categoriesService
        .deleteSubCategory(subcategoryId, this.categoryId)
        .then((res) => {
          this.successedMessage = res.data.result.message;
          this.subCategroies.splice(index, 1);

          setTimeout(() => {
            this.showAlertLoading = false;
            this.successed = true;
          }, 1000);

        })
        .catch((err) => {

          setTimeout(() => {
            this.showAlertLoading = false;
            this.successed = false;
            this.erorrMessage = err.response.data.message;
          }, 1000);

        });
    },
  },
};
</script>

<style>
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #171122;
  color: #fff;
}
</style>
